import * as bartio from '@/assets/data/contracts/bartio.json';
import { Pools } from '@/types/pools';

/*
 * FX Pool Factories
 *
 * @returns {Record<string, string>} - A record of quote token addresses to their corresponding FXPoolDeployer addresses.
 *
 * !Important: The FxPoolDeployer address must be in lower case.
 */
const fxPoolFactories = {
  '0xf5AFCF50006944d17226978e594D4D25f4f92B40':
    '0x894fac6c0933cafabb8a0acaa5e196c3b59dac9f', // NECT
  '0x501dbf23c2b004d751496adc073da3727c5fe80f':
    '0x1ec38696747c6d31b7d8cbe0edcf45a4337d0f51', // hETH
  '0x482c38cd33e79a3e3c1cce792a72d41bafffd416':
    '0x58713ebbe790b76c0b311901f7e61403319cbe88', // hSOL
  '0x3db20aefcd234465c981b87da025711c91add2c3':
    '0x55aa7ed783a8ea1b505c64a94272af7f4b49af7c', // hTIA
  '0x0E4aaF1351de4c0264C5c7056Ef3777b41BD8e03':
    '0x7b59a632c20b0015548cbf61193476664eb900ab', // HONEY
  '0xf3585984B956f2A26694ee1BAede7eAB658A5e7E':
    '0x17d95442085bfa0db18afb7fe1974cbc1cd9cf3c', // USDC
};

const pools: Pools = {
  IdsMap: {},
  Pagination: {
    PerPage: 10,
    PerPool: 10,
    PerPoolInitial: 5,
  },
  BoostsEnabled: true,
  DelegateOwner: '0xba1ba1ba1ba1ba1ba1ba1ba1ba1ba1ba1ba1ba1b',
  ZeroAddress: '0x0000000000000000000000000000000000000000',
  DynamicFees: {
    Gauntlet: [],
  },
  BlockList: [
    '0x3383d4d367565eda32f4dc256cae68279aa9dba300020000000000000000000d',
    '0xf0ddc17ff14c4caa505889e41e5270f6cd63fc4e00020000000000000000000c',
    '0x4baab7794cbdadaa51b360604502e59d6f3a6d9a00020000000000000000000e',
    '0xdba381f74bc0a22fe51092f7ceac257965c49654000200000000000000000006',
    '0xc62ba1c49877488ce15b1cf2623d6735eb7d2ce9000200000000000000000009',
    '0xb47f5649338795d1e2086ee5e7cba23b6f3ed5c2000200000000000000000007',
    '0xd8e1927ed35ea4c20fbe9d8e74a56785c5629da400020000000000000000000f',
    '0x3123d6b55828955425b8cdd8927bf0039e369764000100000000000000000010',
    '0xd915d9644da39515eee59e75889bcf9a127fec2b000200000000000000000004',
    '0x473007d0ef224fe6edcac8a0a9228f0b9571f033000200000000000000000005',
    '0xc0df3392fb02369f18b9854c9d4013d574935631000200000000000000000029',
    '0xce31eff0125d96907d5850c421feef40c8b5a275000000000000000000000001',
    '0x1d516d72e82791eaa3a1f58f0d8a3ba4fd3acef3000200000000000000000016',
    '0xc7f6ae40948d78a818faaa43ad32bd3140b2c9ee00020000000000000000001f',
    '0xde32e19e646c3eee76d5eb4a0b58a9b93cae8f85000100000000000000000019',
    '0xa54024b091adf9533041bfab082e3c3c43fc0ed800010000000000000000001b',
    '0xb72b23fe57d7c4b6522b53ce76028b225839346f000200000000000000000026',
    '0x3736948597d348fbffc367aee39f49070a7ef76f000200000000000000000015',
    '0xc41d21d32bafb1951b0d1b74ba9eef1c4968a011000200000000000000000031',
    '0xf015c9de63f2b3f36d52d1e87e10aa5ff6f77304000200000000000000000034',
    '0xe1df7bf03f0668e33879344727bb6c0b95813bdb00020000000000000000002f',
    '0x04e9d731c7f397fa9941dcc3e625abf1d1f3de84000200000000000000000022',
    '0x3abf295ee1408ff468075bf261319b370067897c00010000000000000000002c',
    '0xa24dbc9c100de60984e4683d13f8a6d42bf22dfc00020000000000000000002b',
    '0x22bdfdfe221777e744e4dae3b12a74e15f1851ab000200000000000000000030',
    '0x0c5e81d4bb2e91f7bef35bf47c54e4ddbed64c45000200000000000000000018',
    '0xe130c8ef76c56d734338a42a2e414f5ea2cef785000200000000000000000021',
    '0x1763fa9b9037485320f690b6ccd97ed13677216f00010000000000000000001a',
    '0xba735576c2f670729a23979d485c88a3287c56f7000200000000000000000025',
    '0xacadff8b89a41eaf0d4eea4f9b92d8a756c27dc8000200000000000000000037',
    '0x19d11539d0f10583e595c9c7f39377516da55d9600020000000000000000002d',
    '0xc67624bf08f5a7621a2266ee0b109a642587bfd3000200000000000000000035',
    '0xc6bc90e0bdf4e30a00bb3bad9a91254ee17b1701000200000000000000000032',
    '0x5daa128dd0df701675a452d0004f51a1e97fda85000200000000000000000033',
    '0x6ee311747a2db39e52aee033d7037776c93a4aff000200000000000000000036',
    '0xfa251c5b86fadf40062bfafb9aac2e18f903d8b000020000000000000000000a',
    '0xef6b5a9517636f4c9f5f9c4eeaad41417ce12a2e000200000000000000000013',
    '0xe6ae547ea1276840c06a61a65fcd2753bdc1f0c900010000000000000000001c',
    '0xe3e8c13c47ce5f13a8efd3dd83a13d81be96eb42000100000000000000000017',
    '0xccbe74ee60b512163fd1b63842861a2743b4b9d900010000000000000000003c',
    '0x1730d5657e367e59e2e3ac40575df1a58a9c425d000200000000000000000042',
    '0x3364cf3398e5405ca9b1d02fb407531154a07865000200000000000000000043',
    '0x3cf3ff89fb56251edc14c87a62453b4dcf0fb727000100000000000000000040',
    '0x3f003156b5bfdc9a2b45ea7d9cbfd77eefe6175d000100000000000000000045',
    '0x68c72084d7c52fc8089364a0b311752f25fd907e00000000000000000000003e',
    '0x6a7022a67ac70c3fcf1a321bc99a3b725ad44500000100000000000000000053',
    '0x6b484c68d54b34224a2e4191395cdade62941f30000200000000000000000044',
    '0x6c4c0ec67ffe908e2dd24169e0ee225b87c3831600020000000000000000004c',
    '0x9b154bd95a63bc06628cc45f0a15e0bed04b9455000100000000000000000051',
    '0x9d63665c649ae87c9d8ab2fb6198692504b5d94900020000000000000000004b',
    '0x9e9a8328a2b1eda5827123e8e81460e81aed776d00010000000000000000003d',
    '0xa8d19cc7e6c3da9df3e6aa75aac5428e61b5f94b000100000000000000000050',
    '0xbc1ad17252c717d9968c5273a587888d8f6d01e4000200000000000000000039',
    '0xbe460e11ea999c276871b41e657e55413bb9244100020000000000000000003b',
    '0xdeac7856d928c93236642a79c7abc686f6392182000200000000000000000048',
    '0xe8fad2957343b06c512bf6bd03fd608ac284d17b000100000000000000000047',
    '0xecb62b5871a6d80d555ebdbd55975f25becc00f900020000000000000000003a',
    '0xee0094073ad5e12da2a8d90b412633344d47a957000200000000000000000041',
    '0xf736b354fb0fd21ee66a8db8ca93b7cc583239bf000100000000000000000046',
    '0x0577070b60b94bd97d633d348b8cab2d8ea4f6f0000100000000000000000052',
    '0x31e1a5695d1fa23ecaffd5d9a611d9a6bbec790f00010000000000000000004a',
  ],
  IncludedPoolTypes: [
    'Weighted',
    // 'Stable',
    // 'MetaStable',
    // 'LiquidityBootstrapping',
    // 'Investment',
    // 'StablePhantom',
    'ComposableStable',
    'FX',
    // 'EulerLinear',
    // 'Gyro2',
    // 'Gyro3',
    // 'GyroE',
  ],
  Stable: {
    AllowList: [],
  },
  Investment: {
    AllowList: [],
  },
  Weighted: {
    // Only effective after given timestamp here: usePool.ts#createdAfterTimestamp
    // see useDisabledJoinPool.ts#nonAllowedWeightedPoolAfterTimestamp for logic.
    AllowList: [],
  },
  Factories: {
    [bartio.WeightedPoolFactory]: 'weightedPool',
    [bartio.ComposableStablePoolFactory]: 'composableStablePool',
    // append fxPoolFactories to the factories
    ...Object.fromEntries(
      Object.values(fxPoolFactories).map(factory => [factory, 'fx'])
    ),
  },
  Stakable: {
    VotingGaugePools: [],
    AllowList: [],
  },
  Metadata: {},
  Deep: [],
  BoostedApr: [],
  DisabledJoins: [], // may contain pool ids with security issues
  NewVersionAvailable: {},
  Deprecated: {}, // for pool migration?
  GaugeMigration: {},
  BrandedRedirect: {},
  ExitViaInternalBalance: [],
  Issues: {},
  Curated: [
    // Burr Pools (FX Pools)
    '0x6d6689c293f481ebe910aaad043914758cd63803000200000000000000000038', // HONEY / NECT
    '0xb15125d352d49c4f44ab010c1ebe418ff27d8e24000200000000000000000054', // rUSD / NECT
    '0x3470b2505db806ce2d5880dcb0eef749c896fb0c000200000000000000000055', // brONEY / NECT
    '0x73e5950dbe2ea183e61710c750f625bca3e3f10e000200000000000000000056', // USDI / NECT
    '0x11534a2e277d644332be88cbb6a4cb33c2209ca9000200000000000000000057', // shETH / hETH
    '0xe5ab21f1f83dd5dcb5939d3a22c6c12592368aef000200000000000000000058', // shSOL / hSOL
    '0xeff9018f94f79eed6545ed2fd1ff6e95a504be7a000200000000000000000059', // shTIA / hTIA

    // MultiStable Pools (Composable Stable Pools)
    '0x76bc701027165de707d38ecd66ad5646cd58915c00000000000000000000005a', // NECT/HONEY/rUSD/brONEY/USDI

    // Generalized Pools (Weighted Pools)
    '0x26266e65406e7795911bc7633f1cdc8d769b2f6e00010000000000000000005c', // shETH:hETH:shSOL:hSOL:shTIA:hTIA:BURR
    '0x434cad4304dc3989e2adbfec362d851a9e89424b00010000000000000000005b', // BURR:BERA:NECT
  ],
  FXPoolFactories: fxPoolFactories,
};

export default pools;
